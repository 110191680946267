import { initializeApp } from "firebase/app"
import { getFirestore } from "firebase/firestore"

const firebaseConfig = {
    apiKey: "AIzaSyD-MWOBrIybVRSBR1eX_9O1egDlE4SIYDw",
    authDomain: "foodstep-coming-soon.firebaseapp.com",
    projectId: "foodstep-coming-soon",
    storageBucket: "foodstep-coming-soon.appspot.com",
    messagingSenderId: "440174973850",
    appId: "1:440174973850:web:4b9f4a4874c2d4b689e023",
    measurementId: "G-H3XGWH3YHM"
};

const app = initializeApp(firebaseConfig)
export const firestore = getFirestore(app)

export default app