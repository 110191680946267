import { useEffect, useState } from 'react';
import './App.css';
import { calculateRemainingTime } from './util';
import { collection, addDoc } from "firebase/firestore"
import { firestore } from './config';
import ImageData from "./imageData.json"
import { Spinner } from 'reactstrap';

function App() {
  const [timer, setTimer] = useState ({
    months: 0,
    days: 0
  })
  const [userInput, setUserInput] = useState("")
  const [isLoading, setLoader] = useState(false)
  const [alert, setAlert] = useState("")
  useEffect (() => {
    setTimer({
      months: Math.floor(calculateRemainingTime()[0]),
      days: calculateRemainingTime()[1]
    })
  }, [])

  const onChange = (event) => {
    setUserInput(event.target.value)
  }

  const onSubmit = () => {
    if (!userInput) {
      setAlert("Please enter Phone number / Email Address")
      return 
    }
    setLoader(true)
    addDoc(collection(firestore, "userInputs"), {
      value: userInput,
    })
    .then(() => {
      setLoader(false)
      setUserInput("")
      setAlert("Thank you for showing interest ! Your email has been registered")
      setTimeout(() => {
          setAlert("")
      }, 3000)
    })
    .catch(err => console.log(err.message))
  }

  return (
    <div>
      <div>
        <img className='logo' alt="food-step" src={`data:${ImageData.logo.mime};base64, ${ImageData.logo.data}`} />
      </div>
      <div style={{display:"flex", justifyContent:"space-between"}}>
        <div style={{position:"absolute", zIndex:"-1"}}>
          <div>
            <img className='food-image' alt="food-step" src={`data:${ImageData['food-image'].mime};base64, ${ImageData['food-image'].data}`} />  
          </div>
          <div>
            <img className='bubble-one' alt="food-step" src={`data:${ImageData['bubble-1'].mime};base64, ${ImageData['bubble-1'].data}`} />
          </div>
        </div>
        {/* <div>
          <img style={{ marginLeft:"68rem" }} alt="food-step" src={`data:${ImageData['bubble-2'].mime};base64, ${ImageData['bubble-2'].data}`} /> 
          <img style={{ marginLeft: "55rem" }} alt="food-step" src={`data:${ImageData['cook-image'].mime};base64, ${ImageData['cook-image'].data}`} />
        </div> */}
      </div>
      <div className='text-container'>
            <div className='text-lines'>
              Craving for ghar ka khaana everyday? <br /> Don’t worry! We are here for you!
            </div>
            <div className='timer-card'>
              <div className='timer-month'>
                <div className='timer-container timer-numbers'>
                  {timer.months}
                </div>
                <div className='timer-title'>MONTHS</div>
              </div>
              <div className='timer-days'>
                <div style={{display:"flex"}}>
                  <div className='timer-container timer-numbers' style={{marginRight:"1.75rem"}}>
                    {Math.floor(timer.days/10)}
                  </div> 
                  <div className='timer-container timer-numbers'>
                    {timer.days%10}
                  </div>
                </div>
                <div className='timer-title'>DAYS</div>
              </div>
            </div>
            <div className='text-lines'>
              We will notify you when we launch! <br /> Thank you for your patience!
            </div>
            <div className='input-container'>
              <input className='input-field' placeholder='Email Address / Phone Number' value={userInput} onChange={onChange} type='text' />
              <button onClick={onSubmit} className='notify-button'>
                {isLoading ? <Spinner /> : 'Notify Me'}
              </button>
            </div>
            <div className='alert-container'>
                {alert}
            </div>
        </div>
    </div>
  );
}

export default App;
